<template>
  <div class="container">
    <a class="btn__back" @click="$router.go(-1)">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <h2 class="leading" v-if="start">Enter your Account Number</h2>
    <h2 class="leading" v-if="end">Is this your Account ?</h2>

    <form class="form" @submit.prevent="submitForm" novalidate="true" v-if="start | end">
      <div class="form__group">
        <label for="accountNumber" class="form__label">
          <input
            type="tel"
            id="accountNumber"
            name="accountNumber"
            placeholder="Account Number"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.accountNumber.$model"
            :class="{ 'is-invalid': submitted && $v.form.accountNumber.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.accountNumber.required"
          >Account number is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.accountNumber.numeric"
          >Needs to be numeric only.</small
        >
      </div>
      <button type="submit" class="btn btn__red btn__group" v-if="start">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </form>

    <h3 v-if="end" class="name-confirm">{{ form.fullName | capitalize }}</h3>

    <p class="retrieve" v-if="start">
      Dial <a href="tel:*822*8#">*822*8#</a> with your Registered Phone Number to retrieve
      Account Number
    </p>

    <div v-if="pending">
      <div class="heading-two">
        <h2>An activation code has been sent to your phone number.</h2>
        <p>
          Dial it via USSD using your phone number linked to this account number -
          <strong>{{ form.accountNumber }}</strong
          >. Kindly return here and click on the Verify button to complete the process.
        </p>
      </div>
      <button
        type="submit"
        class="btn btn__red btn__group btn__full"
        @click="handleVerifySms"
      >
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </div>

    <div class="btn__groups" v-if="end">
      <button class="btn btn__black" @click="goBack">No</button>
      <!-- <button class="btn btn__red btn__group" @click="handleCustomerDetails">
        <loading v-if="loading" />
        <span>Yes</span>
      </button> -->
      <button class="btn btn__red btn__group" @click="handleActivationCode">
        <loading v-if="loading" />
        <span>Yes</span>
      </button>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loading: false,
      submitted: false,
      start: true,
      pending: false,
      end: false,
      form: {
        accountNumber: "",
        fullName: "",
        id: "",
      },
    };
  },
  validations: {
    form: {
      accountNumber: { required, numeric },
    },
  },
  methods: {
    goBack() {
      this.start = true;
      this.end = false;
    },
    handleCustomerDetails() {
      let payload = {
        nuban: this.form.accountNumber,
      };

      this.loading = true;

      api
        .getCustomerDetails(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let customerDetails = response.Data;

            if (customerDetails.cards !== null) {
              this.$store.dispatch("setCustomerDetails", customerDetails);
              this.$router.push("/signup/card-details");
            } else {
              this.$message({
                showClose: true,
                message: `No Cards found`,
                type: "error",
                duration: 10000,
              });
            }
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleVerifySms() {
      let payload = {
        activation_code_id: this.form.id,
        appversion: 1,
      };

      this.loading = true;

      api
        .validateActivationCode(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.handleCustomerDetails();
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleActivationCode() {
      let payload = {
        nuban: this.form.accountNumber,
        appversion: 1,
      };

      this.loading = true;

      api
        .getActivationCode(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userId = response.Data.id;
            this.form.id = userId;
            this.end = false;
            this.pending = true;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        nuban: this.form.accountNumber,
      };

      this.loading = true;

      api
        .getAccountDetailsByNuban(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userDetails = response.Data.BankAccountFullInfo;
            this.form.fullName = userDetails.AccountTitle;
            this.start = false;
            this.end = true;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
